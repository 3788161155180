@import url(https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;500&display=swap);
html, body {
    font-family: 'Work Sans', sans-serif; 
    font-weight: 300;
}

h1, h2, h3, h4 {
    font-weight: 500 !important;
}

.rcs-inner-handle {
    background-color: #96969666 !important;
}

.bp3-menu-item {
    align-items: center;
}

.bp3-dark .bp3-input {
    box-shadow: 
        0 0 0 1px rgba(16,22,26,.4), 
        0 0 0 1px rgba(16,22,26,.4), 
        0 0 0 1px rgba(16,22,26,.4), 
        inset 0 0 0 0 rgba(16,22,26,.3), 
        inset 0 0 0 0 rgba(16,22,26,.4)
}

.bp3-heading {
    color: #15384a;
}

.bp3-dialog-container {
    align-items: flex-start;
}

.number-input {
    font-family: monospace;
}

.emoji-mart {
    border: 0 !important;
}

.fax-preview-portal {
    z-index: 5;
}

.fax-preview-wrapper {
    transition-property: opacity;
    transition-duration: 175ms;
    transition-timing-function: linear;
}

.fax-preview-wrapper.bp3-overlay-enter-active {
    opacity: 1;
}

.fax-preview-wrapper.bp3-overlay-exit-active  {
    opacity: 0;
}

.bp3-overlay-backdrop {
    background-color: rgba(0, 0, 0, 0.85)
}

.bp3-skeleton {
    opacity: 0;
}

#root {
    background-color: #a6cfe8;
}

.bp3-running-text table th, table.bp3-html-table th, .bp3-running-text table td, table.bp3-html-table td {
    vertical-align: middle;
}
